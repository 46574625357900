<template>
  <div>
    <b-table
      striped
      hover
      :items="bankDataList"
      :fields="fields"
      :busy="isLoadingComponent"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(banner)="{ item }">
        <img
          :src="
            item.banner
              ? item.banner
              : require(`@/assets/images/avatars/no-image.png`)
          "
          @error="
            $event.target.src = require(`@/assets/images/avatars/no-image.png`)
          "
          class="image__table rounded"
          alt=""
        />
      </template>

      <template v-slot:cell(logo)="{ item }">
        <img :src="item.logo" class="image__table" alt="" />
      </template>

      <template v-slot:cell(creator)="{ item }">
        {{ item.creator ? item.creator.name : "-" }}
      </template>

      <template v-slot:cell(actions)="{ item }">
        <div>
          <b-button
            variant="danger"
            size="sm"
            :to="'/edit-bank/' + item.uuid"
            class="mb-1 d-block"
          >
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-button>
          <b-button
            variant="outline-danger"
            size="sm"
            :to="'/banks/' + item.uuid + '/participant'"
            class="d-block"
          >
            <feather-icon icon="UsersIcon" class="mr-75" />
            Lihat Partisipan
          </b-button>
        </div>
      </template>
    </b-table>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  BFormFile,
  BFormRadioGroup,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    vSelect,
    quillEditor,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    bankData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    bankData(value) {
      this.bankDataList = value;
    },
    formPayload(value) {
      this.formPayloadBank = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    editId(value) {
      this.editIdModal = value;
    },
  },
  data() {
    return {
      moment,
      required,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      bankDataList: [],
      editIdModal: null,
      formPayloadBank: {
        name: "",
      },
      file_type: "",
      file_url: "",
      file: "fileContent",
      validationsForm: "",
      category: [],
      categorySelected: [],
      fields: [
        { key: "no" },
        { key: "banner" },
        { key: "logo" },
        { key: "actions" },
      ],
      //   filter: {
      //     order_type: "desc",
      //   },
    };
  },
  computed: {
    rows() {
      return this.bankDataList.length;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.ql-editor.ql-blank {
  height: 400px;
}
.media_file {
  width: 400px;
  height: 300px;
}
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}

.image__table {
  width: 150px;
}
</style>

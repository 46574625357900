<template>
    <b-card title="Bank List">
  
      <Table
        :result="result"
        :bankData="bankData"
        :is-loading="isLoading"
        :get-data="getData"
        :update-item="updateItem"
        :current-page="currentPage"
      />
    </b-card>
  </template>
  
  <script>
  import Table from "@/components/bank/Table.vue";
  import { successNotification, errorNotification } from "@/auth/utils";
  import {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BSpinner,
    VBModal,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import _ from "lodash";
  export default {
    components: {
      Table,
      BCard,
      BCol,
      BRow,
      BButton,
      BSpinner,
      BFormInput,
    },
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
    watch: {
      filter: {
        handler: _.debounce(function() {
          this.getData();
        }, 300),
        deep: true,
      },
    },
    data() {
      return {
        currentPage: 1,
        isLoading: false,
        result: [],
        bankData: [],
        link: "",
        isLoadingExport: false,
        formPayload: {
          name: "",
        },
        editId: null,
        filter: {},
        validations: "",
      };
    },
    setup() {
      return {
        successNotification,
        errorNotification,
      };
    },
    created() {
      this.getData();
    },
    methods: {
      getData(page) {
        this.isLoading = true;
        this.currentPage = page;
        const queryParams = this.filter;
        queryParams.page = page;
        this.$http
          .get("/api/v1/admin/banks", {
            params: queryParams,
          })
          .then((response) => {
            this.result = response.data.meta;
            this.bankData = response.data.data;
            this.currentPage = response.data.meta.currentPage;
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      updateItem(slug) {
        alert('alert')
      },
    },
  };
  </script>
  
  <style lang="scss"></style>
  